/* eslint-disable react/no-danger */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Layout, Sidebar, PostSummary, Pagination,
} from '../../components';
import blogPageStyles from './blogPage.module.scss';

const BlogPage = ({ pageContext }) => {
    const {
        prevPageLink, nextPageLink, currentPage, posts, blogPages,
    } = pageContext;
    const blogPage = _.head(blogPages) || {};
    const metaTitle = _.get(blogPage, 'metaTitle') || 'WikiLawn | Lawn Care Advice for your Area | Outdoor Services';
    const metaDescription = _.get(blogPage, 'metaDescription') || 'Lawn Care Advice for your Area | Outdoor Services';

    const {
        main, sideBarDesktop, sideBarMobile, contentContainer,
    } = blogPageStyles;

    return (
        <Layout metaTitle={metaTitle} metaDescription={metaDescription} metaPageNumber={currentPage}>
            <div className={main}>
                <div className={contentContainer}>
                    <div>
                        {_.map(posts, (edge) => {
                            return <PostSummary key={edge.node.path} node={edge.node} />;
                        })}
                    </div>
                    <div className={sideBarDesktop}>
                        <Sidebar />
                    </div>
                </div>
                <div className={sideBarMobile}>
                    <Sidebar />
                </div>
                <Pagination prevPageLink={prevPageLink} nextPageLink={nextPageLink} />
            </div>
        </Layout>
    );
};

BlogPage.propTypes = {
    pageContext: PropTypes.shape({
        posts: PropTypes.arrayOf(PropTypes.shape({})),
        prevPageLink: PropTypes.string,
        nextPageLink: PropTypes.string,
        currentPage: PropTypes.number,
        blogPages: PropTypes.arrayOf(
            PropTypes.shape({
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                heading: PropTypes.string,
                subHeading: PropTypes.string,
            }),
        ),
    }).isRequired,
};

export default BlogPage;
